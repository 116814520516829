import React, { useState, useEffect } from 'react';
import { Button, FormControl, InputLabel, Select, MenuItem, Dialog, 
         DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { obtenerVigilantesPorServicio, agregarTurno } from './FirestoreCuadrantes';

const AutoScheduler = ({ cuadranteId, currentService, servicioId, onScheduleComplete }) => {
  const [vigilantes, setVigilantes] = useState([]);
  const [restricciones, setRestricciones] = useState({
    maxHorasSemanales: 40,
    minHorasDescanso: 12,
    turnosPorDia: 3,
    diasDescansoSemanal: 2
  });
  const [generando, setGenerando] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const cargarVigilantes = async () => {
      try {
        const vigilantesData = await obtenerVigilantesPorServicio(servicioId, currentService);
        setVigilantes(vigilantesData);
      } catch (error) {
        console.error('Error al cargar vigilantes:', error);
      }
    };

    if (servicioId && currentService) {
      cargarVigilantes();
    }
  }, [servicioId, currentService]);

  const generarCuadrante = async () => {
    setGenerando(true);
    try {
      // Definir los turnos estándar
      const turnos = {
        mañana: { inicio: '06:00', fin: '14:00' },
        tarde: { inicio: '14:00', fin: '22:00' },
        noche: { inicio: '22:00', fin: '06:00' }
      };

      // Obtener el mes actual y número de días
      const fecha = new Date();
      const primerDia = new Date(fecha.getFullYear(), fecha.getMonth(), 1);
      const ultimoDia = new Date(fecha.getFullYear(), fecha.getMonth() + 1, 0);
      const numDias = ultimoDia.getDate();

      // Crear matriz de disponibilidad
      const vigilantesDisponibles = vigilantes.map(v => ({
        ...v,
        horasSemanales: 0,
        ultimoTurno: null,
        diasTrabajados: 0
      }));

      // Para cada día del mes
      for (let dia = 1; dia <= numDias; dia++) {
        const fecha = new Date(primerDia.getFullYear(), primerDia.getMonth(), dia);
        
        // Para cada turno del día
        for (const [nombreTurno, horario] of Object.entries(turnos)) {
          // Encontrar vigilante disponible
          const vigilanteDisponible = vigilantesDisponibles.find(v => {
            // Verificar restricciones
            const puedeTrabajar = (
              v.horasSemanales < restricciones.maxHorasSemanales &&
              (!v.ultimoTurno || 
               calcularHorasDescanso(v.ultimoTurno, horario.inicio) >= restricciones.minHorasDescanso) &&
              v.diasTrabajados < (7 - restricciones.diasDescansoSemanal)
            );
            return puedeTrabajar;
          });

          if (vigilanteDisponible) {
            // Crear el turno en Firebase
            const turnoData = {
              fecha: fecha.toISOString().split('T')[0],
              inicioTurno: horario.inicio,
              finTurno: horario.fin,
              vigilante: vigilanteDisponible.persona,
              vigilanteId: vigilanteDisponible.id,
              turno: nombreTurno,
              observaciones: 'Generado automáticamente'
            };

            await agregarTurno(cuadranteId, turnoData, currentService);

            // Actualizar estado del vigilante
            vigilanteDisponible.horasSemanales += 8;
            vigilanteDisponible.ultimoTurno = horario.fin;
            vigilanteDisponible.diasTrabajados++;

            // Resetear contadores en nuevo día/semana si es necesario
            if (fecha.getDay() === 0) { // Domingo
              vigilantesDisponibles.forEach(v => {
                v.horasSemanales = 0;
                v.diasTrabajados = 0;
              });
            }
          }
        }
      }

      onScheduleComplete();
    } catch (error) {
      console.error('Error al generar cuadrante:', error);
    } finally {
      setGenerando(false);
      setOpen(false);
    }
  };

  const calcularHorasDescanso = (ultimoTurno, siguienteTurno) => {
    const [uHoras, uMinutos] = ultimoTurno.split(':').map(Number);
    const [sHoras, sMinutos] = siguienteTurno.split(':').map(Number);
    let horas = sHoras - uHoras;
    if (horas < 0) horas += 24;
    return horas + (sMinutos - uMinutos) / 60;
  };

  return (
    <>
      <Button 
        variant="contained" 
        color="primary" 
        onClick={() => setOpen(true)}
        disabled={!cuadranteId || !servicioId}
      >
        Generar Cuadrante Automático
      </Button>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Configurar Generación Automática</DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="normal">
            <InputLabel>Máximo Horas Semanales</InputLabel>
            <Select
              value={restricciones.maxHorasSemanales}
              onChange={(e) => setRestricciones({
                ...restricciones,
                maxHorasSemanales: e.target.value
              })}
            >
              <MenuItem value={35}>35 horas</MenuItem>
              <MenuItem value={40}>40 horas</MenuItem>
              <MenuItem value={45}>45 horas</MenuItem>
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal">
            <InputLabel>Mínimo Horas Descanso</InputLabel>
            <Select
              value={restricciones.minHorasDescanso}
              onChange={(e) => setRestricciones({
                ...restricciones,
                minHorasDescanso: e.target.value
              })}
            >
              <MenuItem value={10}>10 horas</MenuItem>
              <MenuItem value={12}>12 horas</MenuItem>
              <MenuItem value={14}>14 horas</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancelar</Button>
          <Button 
            onClick={generarCuadrante} 
            disabled={generando}
            variant="contained" 
            color="primary"
          >
            {generando ? 'Generando...' : 'Generar'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AutoScheduler;